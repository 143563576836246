<template>
  <div class="box2">
    <div class="flexSt list">
      <div class="title">课前 :</div>
      <el-image
        class="img"
        v-for="(item, index) in form.front"
        :src="item.file_url"
        :preview-src-list="front"
      >
      </el-image>
    </div>
    <!-- <div class="flexSt list">
      <div class="title">课中 :</div>
      <el-image
        class="img"
        v-for="(item, index) in srcList"
        :src="item"
        :preview-src-list="srcList"
      >
      </el-image>
    </div> -->
    <div class="flexSt list">
      <div class="title">课后 :</div>
      <el-image
        class="img"
        v-for="(item, index) in form.behind"
        :src="item.file_url"
        :preview-src-list="behind"
      >
      </el-image>
    </div>
  </div>
</template>
<script>
import { courseLogprepare, elegantMoment } from "@/api/classroon/record";

export default {
  data() {
    return {
      srcList: [],
      tableData: [],
      Info: {
        id: "",
      },
      form: {
        front: [],
        middle: [],
        behind: [],
      },
      front: [],
      behind: [],
    };
  },
  mounted() {
    if (this.$route.query.val) {
      this.Info = JSON.parse(this.$route.query.val);
      let val = this.Info;
      console.log(val);
      this.Info.showtime = val.date + val.time;
      this.Info.course_typeName =
        val.course_type == 1 ? "常规体育课" : "大课间";
      this.handleGetelegantMoment();
    }
  },
  methods: {
    async handleGetelegantMoment() {
      let data = {
        id: this.Info.id,
      };
      await elegantMoment(data).then((res) => {
        this.form = res.data;
        this.front = this.form.front.map((item) => {
          return item.file_url;
        });
        this.front = this.form.front.map((item) => {
          return item.file_url;
        });
        this.behind = this.form.behind.map((item) => {
          return item.file_url;
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box2 {
  padding: 30px 100px 30px 106px;
  box-sizing: border-box;
}
.list {
  flex-wrap: wrap;

  margin-bottom: 20px;
  .title {
    margin-right: 10px;
    color: rgba(16, 16, 16, 1);
    font-size: 14px;
  }
  .img {
    width: 215px;
    height: 165px;
    margin-right: 20px;
    object-fit: cover;
  }
}
</style>
